<template>
  <b-container id="schedule-resume-table">
    <table class="table-eyecare">
      <thead>
        <tr>
          <th @click="handleOrder('date')" class="cursor-pointer" >
            <b-row>
              <span>Horário</span>
              <OrdenateIconHandler
                :position="order.date"
              />
            </b-row>
          </th>
          <th @click="handleOrder('patients')" class="cursor-pointer" >
            <b-row>
              <span>Pacientes</span>
              <OrdenateIconHandler
                :position="order.patients"
              />
            </b-row>
          </th>
          <th>
            <b-row>
              <span>Procedimento</span>
            </b-row>
          </th>
          <th>
            <b-row>
              <span>Convênio</span>
            </b-row>
          </th>
          <th v-if="user.type.includes('DOCTOR')">
            <b-row>
              <span>Descrição</span>
            </b-row>
          </th>
          <th>
            <b-row class="justify-content-end">
              <b-button variant="link" @click="goTo('/agenda')">
                Ver agenda
              </b-button>
            </b-row>
            
          </th>
        </tr>
      </thead>
      <tbody v-if="!loading && appointments.length">
        <tr
          class="appointment"
          v-for="(appointment, index) in appointments"
          :key="index"
        >
          <td>
            <div>
              {{ moment((appointment.clinic?.attendance_type === 'ARRIVAL' && appointment?.status_time) || appointment.start_datetime).format('HH[h]mm') }}
            </div>
            <JourneyTimeTag :appointment="appointment" />
          </td>
          <td
            class="cursor-pointer"
            @click="openPatientModal(appointment.patient, appointment?.status)"
          >
            <b-row align-v="center">
              <b-col cols="12">
                <ScheduleTablePatientInformation :appointment="appointment" :status="appointment.status" />
              </b-col>
            </b-row>
          </td>
          <td>
            <div :id="`popover-target-${index}`" class="mb-1">
              <span class="mb-1">
                <InfoWarning v-if="appointment?.appointment_items.find(a => a?.item?.deleted_at)" class="icon stroke pt-2 " />
                <label class="inline-overflow line-height-100 mb-0 line-height-100 v-align-middle">
                  {{ 
                    appointment?.appointment_items[0]?.item?.name?.substring(0, 35)
                  }}
                  {{ 
                    (appointment?.appointment_items[0]?.item?.name?.length > 35 ? '...' : '')
                  }}
                </label>
              </span>
              <span
                class="tag ml-2 mr-2 cursor-pointer"
                v-if="appointment?.appointment_items.length > 1"
              >
                + {{ appointment?.appointment_items.length -1  }}
              </span>
              <b-popover
                class="items-container"
                :target="`popover-target-${index}`"
                triggers="hover"
                placement="bottom"
              >
                <p class="mb-3">
                  {{appointment?.patient?.name}}
                  ({{ 
                    appointment?.patient?.birthday
                    ? `${moment().diff(appointment?.patient?.birthday, 'years')} Anos`
                    : 'Sem idade cadastrada'
                  }})
                  <WhatsAppIcon
                    v-if="appointment?.patient?.cellphone || appointment?.patient?.cellphone2"
                    class="ml-3 cursor-pointer"
                    style="cursor: pointer"
                    @click="openWhatsApp(appointment?.patient?.cellphone ?? appointment?.patient?.cellphone2)"
                  />
                </p>
                <div class="mb-1"
                  v-if="appointment?.room?.name"
                >
                  <Room class="icon"/>
                  {{ appointment?.room?.name }}
                </div>
                <label class="mb-1" v-can="'CcProc5'">
                  <MoneyIcon class="ml-0"/>
                  R$ {{ appointment.appointment_items.reduce((total, item) => {
                      return total + item?.value
                    }, 0)
                  }}
                </label>
                <p
                  v-for="item in appointment?.appointment_items"
                  class="pl-2 pr-4"
                  :key="item.id"
                >
                  <b-row>
                    <ItemIconHandler :type="item?.item?.type" v-if="item?.item?.type"/>
                    <span class="mt-1">{{ item?.item?.name }}</span>
                    <div v-if="item?.item?.deleted_at && moment(item?.item?.deleted_at).format('YYYY-MM-D') >= moment().format('YYYY-MM-D')" class="custom-invalid-feedback">
                      Item acima excluído. Não estará disponível na pré-consulta e exames/procedimentos. Por favor, atualize o item no agendamento para habilitá-lo e realizar o atendimento.
                    </div>
                  </b-row>
                </p>
                <p class="mt-2" v-if="appointment?.description">
                  <Comment class="icon bg" />
                  {{ appointment?.description.substring(0, 1000) }}
                  {{ appointment?.description.length > 1000 ? '...' : '' }}
                </p>
              </b-popover>
            </div>
            <div>
              <Telemedicine
                class="icon md"
                v-if="appointment?.telemedicine"
              />
              <Appointment
                class="icon lg"
                v-if="appointment?.appointment_items.find(a => a?.item?.type === ITEM.ITEM_TYPE_APPOINTMENT)"
              />
              <Clock
                class="icon clock md mr-1"
                v-if="appointment?.type === 'Encaixe'"
              />
              <Procedure
                class="icon sm"
                v-if="appointment?.appointment_items.find(a => a?.item?.type === ITEM.ITEM_TYPE_PROCEDURE)"
              />
              <Return
                class="icon md"
                v-if="appointment?.appointment_items.find(a =>a?.item?.type === ITEM.ITEM_TYPE_RETURN)"
              />
              <Exam
                class="icon sm"
                v-if="appointment?.appointment_items.find(a => a?.item?.type === ITEM.ITEM_TYPE_EXAM)"
              />
              <Surgical
                viewBox="0 0 20 20"
                class="icon surgical lg"
                v-if="appointment?.appointment_items.find(a => a?.item?.type === ITEM.ITEM_TYPE_SURGICAL)"
              />
              <Product
                class="icon sm"
                v-if="appointment?.appointment_items.find(a => a?.item?.type === ITEM.ITEM_TYPE_PRODUCT)"
              />
            </div>
          </td>
          <td>
            <div
            v-if="appointment?.appointment_items[0]?.health_plan"
            :id="`health-plans-${index}`"
            class="row ml-1"
            >
              <div class="healthPlan">
                {{
                  appointment?.appointment_items[0]?.health_plan?.fantasy_name
                  ?? appointment?.appointment_items[0]?.health_plan?.company_name
                }}
              </div>
              <span
                class="tag ml-2 mr-2 cursor-pointer"
                v-if="handleAppointmentHealthPlans(appointment?.appointment_items).length > 1"
              >
                + {{ handleAppointmentHealthPlans(appointment?.appointment_items).length -1  }}
              </span>
            </div>
            <span v-else>
              Particular
            </span>
            <b-popover
              class="items-container"
              :target="`health-plans-${index}`"
              triggers="hover"
              placement="bottom"
            >
              <p v-for="(plan, i) in handleAppointmentHealthPlans(appointment?.appointment_items)" :key="`popover-${i}`">
                {{ plan }}
              </p>
            </b-popover>
          </td>
          <td class="minimum_resolution" v-if="user.type.includes('DOCTOR')" :id="`annotation-${appointment?.id}`" >
            <div class="annotation-container">
              <span v-if="appointment?.description">
                {{ appointment?.description.substring(0, 1000) }}
                {{ appointment?.description.length > 1000 ? '...' : '' }}
              </span>
              <b-popover
                v-if="appointment?.description?.length"
                :target="`annotation-${appointment?.id}`"
                triggers="hover"
                placement="bottom"
              >
                <span v-if="appointment?.description">
                  {{ appointment?.description.substring(0, 1000) }}
                  {{ appointment?.description.length > 1000 ? '...' : '' }}
                </span>
              </b-popover>
            </div>
          </td>
          <td>
            <b-container class="btn-container justify-content-end">
              <b-button
                v-if="user.type === 'SECRETARY'"
                variant="primary"
                class="mr-2"
                @click="openAppointmentModal(appointment?.id)"
              >
                <Annotation />
              </b-button>
              <b-button
                variant="primary"
                @click="openAppointmentModal(appointment?.id)"
                v-b-tooltip.hover.bottom="'Visualizar agendamento'"
              >
                <Calendar class="calendar-icon"/>
              </b-button>
              <b-button
                variant="primary"
                class="ml-2"
                @click="onStartClick(appointment)"
                :disabled="!canStartAttendance(appointment) || !validProcedure(appointment)"
                v-b-tooltip.hover.bottom="`${ appointment?.attendance.find(att => att.type === 'ATTENDANCE') ? 'Continuar' : 'Iniciar'} atendimento`"
              >
                <Play class="play-icon"/>
              </b-button>
            </b-container>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="loading || !appointments.length" class="loading-container">
      <b-spinner
        v-if="loading"
        class="mt-5 mb-5"
        variant="primary"
      ></b-spinner>
      <span
        v-else
        class="mt-5"
      >
        Nenhum agendamento disponível
      </span>
    </div>

    <Profile
      :id="selectedPatient?.id"
      :name="selectedPatient?.name"
      :birthday="selectedPatient?.birthday"
      :picture="selectedPatient?.picture"
      :status="selectedPatient?.status"
    />
  </b-container>
</template>

<script>
import * as ITEM from '@items/utils/constants'
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'

export default {
  components: {
    ScheduleTablePatientInformation: () => import('./ScheduleTablePatientInformation.vue'),
    Annotation: () => import('@/assets/icons/paper.svg'),
    Appointment: () => import('@/assets/icons/appointment.svg'),
    Calendar: () => import('@/assets/icons/calendar.svg'),
    Clock: () => import('@/assets/icons/clock.svg'),
    Comment: () => import('@/assets/icons/Comment-lines.svg'),
    Exam: () => import('@/assets/icons/exam.svg'),
    ItemIconHandler: () => import('@/components/Overview/ItemIconHandler'),
    MoneyIcon: () => import('@/assets/icons/cash.svg'),
    OrdenateIconHandler: () => import('@/components/General/OrdenateIconHandler.vue'),
    Play: () => import('@/assets/icons/play.svg'),
    Procedure: () => import('@/assets/icons/procedure.svg'),
    Product: () => import('@/assets/icons/product.svg'),
    Profile: () => import('./PatientProfile.vue'),
    Return: () => import('@/assets/icons/return.svg'),
    Room: () => import('@/assets/icons/room.svg'),
    Surgical: () => import('@/assets/icons/surgical.svg'),
    Telemedicine: () => import('@/assets/icons/telemedicine.svg'),
    WhatsAppIcon: () => import('@/assets/icons/whatsapp-icon.svg'),
    JourneyTimeTag: () => import('@/components/Overview/JourneyTimeTag'),
    InfoWarning: () => import('@/assets/icons/info-circle-warning.svg'),
},
  props: {
    appointments: Array,
    loading: Boolean,
  },

  data() {
    return {
      ITEM,
      clinic: getCurrentClinic(),
      selectedPatient: {},
      user: getCurrentUser(),
      order: {
        date: 'asc',
        patients: null,
      }
    }
  },

  methods: {

    goTo(path) {
      this.$router.push(path)
    },
    openPatientModal (patient, status) {
      this.selectedPatient = {
        ...patient,
        status: status
      }
      this.$bvModal.show('patient-profile-modal')
    },
    openWhatsApp(number) {
      const whatsappNumber = number.replace(/\D/g, '')
      if(whatsappNumber.length < 10){
        this.$toast.warning('Número de telefone sem DDD')
        return
      }
        window.open(`https://api.whatsapp.com/send?phone=55${whatsappNumber}`, '_blank')
    },
    handleAppointmentHealthPlans(appointmentItems) {
      const plans =  appointmentItems.map(item => {
        return item?.health_plan?.fantasy_name ?? item?.health_plan?.company_name
      })

      return [...new Set(plans)]
    },
    openAppointmentModal(appointmentId){
      this.$emit('openAppointmentModal', appointmentId)
    },
    handleOrder (key) {
      if(!this.order[key])
        this.order[key] = 'asc'
      else if(this.order[key] === 'asc')
        this.order[key] = 'desc'
      else if(this.order[key] === 'desc')
        this.order[key] = null

      this.$emit('onOrdenate', this.order)
    },
    validProcedure(appointment){
      if(appointment?.appointment_items){
        return !appointment?.appointment_items.find(a => a?.item?.deleted_at)
      }
      return true
    },
    canStartAttendance (appointment) {
      return !(['MISSED', 'CANCELLED', 'CANCELLED_BY_PATIENT', 'FINISHED', 'IN_PRE_CONSULTATION'].includes(appointment?.status))
    },
    onStartClick(appointment) {
      if (!this.canStartAttendance(appointment)) {
        this.$toast.warning('O status deste agendamento não permite iniciar um atendimento')
        return
      }

      this.handleStartAttendance(appointment)
    },
    handleStartAttendance (appointment) {
      const attendance = appointment?.attendance.find(att => att.type === 'ATTENDANCE')

      if (attendance) {
        const attendance = appointment.attendance.find(att => att.type === 'ATTENDANCE')
        this.goTo(`/pacientes/${appointment.patient.id}/consulta/${attendance?.id}`)
      } else {
        this.startAttendance(appointment)
      }
    },
    async getDefaultFormId() {
      const user = JSON.parse(localStorage.getItem('user'))
      try {
        const response = await this.api.getForms(this.clinic.id, user.id, 'ATTENDANCE');
        const form = response.data.find(f => f.default);
        return form?.id;
      } catch (error) {
        this.$toast.error('Não foi possível obter o modelo de consulta padrão, contate o suporte se o erro persistir')
      }
    },
    async startAttendance(appointment) {
      const isLoading = this.$loading.show()
      const formId = await this.getDefaultFormId()

      try {
        const response = await this.api.startAttendance(this.clinic.id, appointment.patient.id, appointment.id, formId)
        const resData = response.data
        this.$router.push(`/pacientes/${appointment.patient.id}/consulta/${resData.id}`)
      } catch (error) {
        this.$toast.error('Não foi possível iniciar o atendimento, contate o suporte se o erro persistir')
      } finally {
        isLoading.hide()
      }
    },
  }
}
</script>

<style lang="scss">
#schedule-resume-table {
  .name-width{
    width: 80% !important;
  }

  table {
    width: 100%;
    * {
      font-size: 14px;
    }
    tr {
      margin-bottom: 0.5rem;
    }

    th {
      color: var(--dark-blue) !important;
    }

    td {
      font-weight: 600;
      max-width: 35ch;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:not(:last-child) {
        padding: 8px 16px 8px 0;
      }

      &:last-child {
        padding: 0;
      }
    }

  }

  .items-container {
    p {
      color: var(--type-active);
      font-weight: 600;
    }
  }

  .annotation-container {
    display: contents;

    span {
      display: contents;
    }
  }

  .loading-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .span {

    margin-left: 0px;
    margin-right: 0px;
  }

  .row {
    display: flex;
    flex-direction: row !important;
    margin-left: 0px;
    margin-right: 0px;
  }

  .column {
    margin-bottom: 0px !important;
    flex-direction: column !important;
    padding-left: 10px;
    justify-content: center;
    display: inline-flex
  }

  .justify-content-end {
    display: flex;
    justify-content: end;
    border-bottom: 0px !important
  }

  .text-align-start {
    text-align: start;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .icon {
    margin-right: 5px;
    fill: var(--blue-500);
    width: 20px;

    &.surgical path {
      fill: var(--blue-500);
    }
  }
  .clock {
    fill: var(--blue-500);
    stroke: var(--neutral-200);
    border: 1px solid var(--blue-500);
    border-radius: 100%;
  }

  .v-align-middle {
    vertical-align: middle;
  }

  .inline-overflow {
    @media(max-width: 1280px){
        max-width: 50ch !important;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .line-height-100 {
    line-height: 100%;
  }

  .calendar-icon {
    fill: white;
    width: 18px;
    height: 18px;
  }

  .play-icon {
    fill: white;
    width: 15px;
    height: 18px;
    padding-left: 2px;
  }

  .tag {
    font-size: 10px;
    font-weight: 700;
    padding: 2px 10px 2px 6px;
    color: var(--type-active);
    background: var(--neutral-200);
    border-radius: 16px;
  }

  .healthPlan {
    max-width: 20ch !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }


  .minimum_resolution {
      @media(max-width: 1280px){
        display: none !important;
      }
    }

    .autoFillBtn {
      padding-left: 8px !important;
      margin-bottom: 2px !important;
    }
}
</style>
